<template>
	<div class="agreement">
		<cjl-fw
			v-if="$store.getters['user/getAppid'] === 'wx41c1352ffcf95479'"
		></cjl-fw>
		<yzy-fw v-else></yzy-fw>
	</div>
</template>

<script>
import YzyFw from "./components/yzy/fw.vue";
import CjlFw from "./components/cjl/fw.vue";
export default {
	components: {
		YzyFw,
		CjlFw,
	},
};
</script>
