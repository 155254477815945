<template>
	<div class="agreement">
		<div v-html="content"></div>
	</div>
</template>

<script>
export default {
	data: () => ({
		content: `<p class="paragraph text-align-type-left" style="margin: 0 0 8px;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">更新日期：2023年</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">月</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">21</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">日</span>
</p>
<p class="paragraph text-align-type-left" style="margin: 0 0 18px;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">生效日期：</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">2023年</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">月</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">21</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;letter-spacing: 0;vertical-align: baseline">日</span>
</p>
<p class="paragraph text-align-type-center" style="margin: 0 0 4px;text-align: center;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 18px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">服务协议</span>
</p>
<p class="paragraph text-align-type-left" style=";font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: Calibri;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">一、您与</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的关系&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">1.1 &quot;</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">&quot;指北京</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">微联动网络</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">科技有限公司（以下简称“我们”）开发、运营的产品及服务，包括</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">但不限于</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline"> App、</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">网站、</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline"> H5 页面以及</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: SimHei;letter-spacing: 0;vertical-align: baseline">数字营销服务小程序</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">1.2&nbsp;您对</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">的使用适用《</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;letter-spacing: 0;vertical-align: baseline">服务协议》（以下简称“本协议”）。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1.3&nbsp;除本协议以外，我们已经发布的或将来可能发布的有关我们向您提供产品或服务的协议、服务规则、政策、须知、声明等内容，均视为本协议的补充协议，作为本协议不可分割的组成部分，与本协议具有同等法律效力。请您及时关注并阅读前述相关补充协议。相关补充协议的部分或全部条款被认定无效的，不影响其它协议或协议条款的效力。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">二、接受本协议</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">2.1&nbsp;使用服务必须首先同意本协议，如果您不接受本协议则不可使用服务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">2.2&nbsp;您可通过下列方式接受本协议：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）在有关任何服务的用户界面上</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">向您提供的选择处点击以接受或同意本协议；或</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）实际使用服务。在此情况下，您理解并同意</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">视您自使用服务时起接受本协议。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">2.3&nbsp;在下列情况下您不可使用服务且不能接受本协议：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）您未达到与</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">订立有约束力的合同的法定年龄，或</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）根据中国或其他国家（包括您居住的或您从那里使用服务的国家）的法律，您是被禁止接受服务的人。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">三</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">提供服务</span>
</p>
<p class="paragraph text-align-type-justify" style=";text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">3.1&nbsp;我们在全国范围内拥有子公司和关联法律实体（&quot;子公司和关联公司&quot;）。在某些时候，我们将通过这些公司的产品向您提供服务,您认知并同意您在长颈鹿智慧医学平台使用的服务将会由关联公司及其关联产品配合完成。（关联公司包含但不仅限于：北京智煜健康科技有限公司、银川麦迪卫康互联网医院有限公司等；关联产品包含但不仅限于：医阵营APP、小程序、网页端等）。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">3</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">不断创新以向其用户提供最优体验。我们有权根据法律法规、国家标准及业务运营之需要适时修改本协议、变更我们提供的服务的形式和本质，且不构成对本协议的违反；如您继续使用</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">，表明您认可并同意我们的服务和本协议的内容，否则，请您停止使用</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">3</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;您认知并同意，如果</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">禁用对您的帐户的访问权，则您可能被阻止获得服务、您的帐户资料或包含在您帐户中的任何文件或其他内容。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">3</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;您认知并同意，尽管</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">可能目前没有设置您可通过服务发送或接收的传输数量或用于提供任何服务的存储空间的上限，但</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">可自行决定在任何时候设置上限。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">四</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、账户注册</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">4</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;您在使用</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">时需要注册一个账户,否则我们无法为您提供服务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">4</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;账户注册条件：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）您应是18周岁以上的自然人，具有民事权利能力，并能够独立承担民事责任。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）如您为未满18周岁的未成年人或存在其他具有限制民事行为能力的情形，请您立即停止注册或使用我们的服务，否则我们有权随时暂停或终止您的用户资格。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">4</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;完成注册：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）您应当按照注册页面的提示，提供真实、完整、准确的信息，您不得使用他人的信息注册账户或向我们提供其他的虚假信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">账户的所有权归我们所有，您完成申请注册后，仅获得账户的使用权。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">4</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;信息真实性保证：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）如发现您以虚假或不实信息骗取注册，我们有权不经通知单方采取限期改正、删除资料、限制或暂停使用、冻结或注销账户并终止对您提供部分或全部服务等措施。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）您应及时更正不实信息，更新、完善您的相关信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（C）如您未及时更正、更新信息导致我们无法按协议约定向您提供服务，或导致您在使用我们产品或服务过程中产生任何损失，应由您自行承担。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（D）我们对以上原因造成的服务暂停、终止不承担任何责任，您应当承担一切不利后果。如因此给我们造成损失的，您应当承担相应的责任。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">五</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、您对服务的使用</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;我们给予您一项个人的、全世界范围内的、不可转让、可撤销及非排他性的许可，以使用我们的软件及服务。除另有书面约定，您仅可以为非商业目的在相应的智能移动终端设备上安装、使用、运行我们的软件及服务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;为获得某些服务，您可能会被要求提供自身信息（如身份或联系资料）作为服务的登记程序的一部分，或作为您持续使用服务的一部分。您同意您给予</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的任何登记信息均是准确、正确和最新的。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;您同意仅为本协议及任何适用法律、法规或有关辖区内公认的惯例或准则（包括关于数据或软件向或</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">从</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">其他相关国家出口的任何法律）所允许的目的使用服务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;您同意您不从事妨碍或者破坏服务（或与服务连接的服务器及网络）的任何活动。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.5&nbsp;除非您在与</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的单独协议中获得特别允许，否则您同意您不为任何目的再制作、复制、拷贝、出售、交易或转售服务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.6&nbsp;你同意不得以任何方式利用</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">直接或间接从事违反中国法律以及社会公德的行为，</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">有权对违反上述承诺的内容予以删除。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.7&nbsp;用户不得利用</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">服务制作、上载、复制、发布、传播或者转载如下内容：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）&nbsp;&nbsp;反对宪法所确定的基本原则的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）&nbsp;&nbsp;危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（C）&nbsp;&nbsp;损害国家荣誉和利益的； 煽动民族仇恨、民族歧视，破坏民族团结的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（D）&nbsp;&nbsp;歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（E）&nbsp;&nbsp;宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（F）&nbsp;&nbsp;煽动民族仇恨、民族歧视，破坏民族团结的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（G）&nbsp;&nbsp;破坏国家宗教政策，宣扬邪教和封建迷信的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（H）&nbsp;&nbsp;散布谣言，扰乱社会秩序，破坏社会稳定的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（I）&nbsp;&nbsp;散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（J）&nbsp;&nbsp;侮辱或者诽谤他人，侵害他人合法权益的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（K）&nbsp;&nbsp;法律、行政法规禁止的其他内容。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.8&nbsp;您同意就您违反您在本协议项下的义务以及任何该等违反的后果（包括</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">遭受的任何损失或损害）承担责任（</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">不对您或任何第三方承担责任）。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.9&nbsp;我们有权基于单方独立判断，在认为可能发生危害国家安全、公共安全、社会公共利益、我们或第三方合法权益等情形时，不经通知而限制、冻结、注销您的账户，暂停或终止向您提供本协议项下的全部或部分服务，并将您的相关数据删除，且无需对您承担任何责任。该等情形包括但不限于：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）您为注册账户而提供的信息不具有真实性、完整性或准确性，包括但不限于盗用他人证件信息注册等；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）将账户转让、出售、出租、出借或分享给他人使用；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（C）盗取或非法访问、滥用其他用户账户或账户内的信息；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（D）恶意虚构事实、隐瞒真相以误导、欺骗他人；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（E）删除我们的产品所有关于著作权的信息，对我们的产品或服务进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件源代码；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（F）对我们的产品进行商业性复制、更改、修改、挂接运行，或制作任何衍生作品或产品，使用插件、外挂或非经授权的工具接入、干扰、影响我们的产品或服务；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（G）限制、禁止或通过其它方法干扰、影响他人使用</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">，或干扰网络的正常运行，如发布或传输以下性质的任何信息或软件：包含蠕虫、病毒或其它有害功能，或生成的流量级别可能妨碍其他人使用、发送或检索信息；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（H）通过网络爬虫或其他自动方式访问或尝试访问我们的产品或服务，或违反我们的产品或服务中的robots.txt或类似文件；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（I）对我们的产品或服务相关信息内容等数据进行商业性使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（J）出于恶意进行的任何操作，或可能损害针对我们的产品或服务的任何评级或评论，以及任何可能影响我们的产品或服务完整性或准确性的任何行为；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（K）侵犯或可能侵犯我们或任何第三方的知识产权、个人信息、隐私权或其他合法权益；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（L）从事违反法律或本协议的其他行为。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.10&nbsp;如您在使用</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的过程中，违反法律或本协议的任何规定，导致我们、其他用户或任何第三方损失，您应当承担全部的责任，我们不为此承担任何责任。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">六</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、您的密码和帐户安全</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;您应当妥善保管自己的账号、密码及其他有效识别信息。</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">因您个人原因导致的账号、密码或其他有效识别信息泄露、丢失或账号和密码被窃取、篡改等导致的一切损失，由您自行承担。您须对使用账号、密码所采取的一切行为负责，我们不承担任何责任。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;如您发现账号、密码被他人冒用或盗用，请立即以有效方式通知我们，要求我们暂停服务。</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">我们在采取行动的合理期限内，不对您的损失承担任何责任。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;您不得将账号、密码以及全部或部分的账户权益转让、出售、出租、出借或分享给他人。如因您将账号、密码以及全部或部分的账户权益转让、出售、出租、出借或分享给他人，导致的您的个人权益受损或发生可能侵犯第三方的个人信息、隐私权或其他合法权益，我们无需对为此承担任何责任，并有权为了您的个人安全、他人合法权益，不经通知而限制、冻结、注销您的账户，暂停或终止向您提供本协议项下的全部或部分服务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;请您在向运营商申请注销手机号，或手机号因其他原因被注销的时候，及时更新</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">账号绑定的手机号信息。如因您不及时更新</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">账号绑定的手机号信息而导致用户数据泄露或您的其他权益受损，我们不承担任何责任。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">七</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、隐私与您的个人信息</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">7.</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1&nbsp;关于</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的数据保护惯例的信息，请查阅</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">。该政策解释了</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">如何处理您的个人信息，并在您使用服务时保护您的隐私。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">7</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;您同意按照</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的隐私政策使用您的数据。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">八</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、服务内容</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">8</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;您理解，作为服务的一部分或通过您使用服务得到的所有信息（如数据文件、书面文本、电脑软件、音乐、音像文件或其他声音、图片、录像或其他图像），完全由该内容出处的人员负责。所有该等信息下称&quot;内容&quot;。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">8</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;您应意识到作为服务一部分展示给您的内容，包括但不限于服务中的广告及服务中的赞助内容，可能受向</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">提供内容的赞助者或广告商（或代表其的其他人员或公司）所拥有的知识产权的保护。您不得修改、租赁、出租、借贷、出售、分发该内容（无论全部还是部分）或根据该内容创作衍生作品，除非我们或内容所有人在单独协议中特别告知您可以为之。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">8</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;我们保留从任何服务中筛选、审阅、标明、过滤、修订、拒绝或删除任何或所有内容的权利（但无义务这样做）。就某些服务而言，</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">可提供滤除明确色情内容、政治相关内容的工具。此外，还有可以通过商业渠道获得的服务和软件能够限制访问令您反感的材料。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">8</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;您理解：通过使用</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">服务，您可能会接触到您觉得冒犯的、粗鄙的、反感的内容，您使用服务时与此相关的风险由您自行承担。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">8</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.5&nbsp;您同意独自就您在使用服务时创作、传送或展示的任何内容以及您做出该等行为的后果（包括</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">可能遭受的任何损失或损害）承担责任（</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">不对您或任何第三方承担责任）。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">九</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、专有权利</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">9</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;您认知并同意，我们（或</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的许可方）对服务拥有一切法定权利、所有权和利益，包括存在于服务中的任何知识产权（无论该等权利是否已经登记，也不论该等权利在世界的何等地方存在）。您进一步认知，服务可能包括我们指定为保密的信息，未经我们事先书面同意，您不得披露该等信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">9</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;除非您与我们另有书面协议，否则本协议中的任何规定均未给予您使用</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">任何商号、商标、服务标记、标识、域名及其他显著品牌特征的权利。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">9</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;如果您在与我们的单独书面协议中被给予一项使用上述品牌特征的明确的权利，则您同意您在使用该等品牌特征时遵守该协议、本协议的任何适用规定以及不时更新的</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">品牌特征使用指南。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">9</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;除第11条规定的限制许可外，我们认知并同意，我们不在本协议项下获得您（或您的许可方）对在服务上或通过服务提交、张贴、传输或展示的任何内容的任何权利、所有权或利益，包括该内容中存在的任何知识产权（无论该等权利是否已经登记，亦不论该等权利在世界的何等地方存在）。您应自行维护上述权益，</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">没有义务代表您这样做。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">9</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.5&nbsp;您同意您不得删除、掩藏或改动服务所附的或包含的任何专有权利声明（包括著作权和商标声明）。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">9</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.6&nbsp;在使用服务时，您不得导致混淆该等商标、名称或标识的所有者或授权用户的方式使用任何公司或组织的商标、服务标识、商号、标识。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">十、您对内容的许可</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">0</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;您保留你在服务上或通过服务提交、张贴或展示的内容中已持有的著作权及任何其他权利。通过提交、张贴或展示内容，您给予我们一项永久性的、不可撤销的、世界范围内的、免交使用费的及非排他性的许可，以复制、改编、修改、翻译、发布、公开实施、公开展示及分发您在服务上或通过服务提交、张贴或展示的任何内容。此项许可仅以使</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">可以展示、分发及宣传服务为目的，并可按某些服务的附加条款就该等服务撤销此项许可。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">0</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;您同意此项许可包括一项权利，使我们可将该等内容提供给与</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">有提供联合服务的关系的其他公司、组织或个人，并就联合服务的提供使用该内容。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">10</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;您理解并同意，我们在实施所需的技术措施向用户提供服务时，可在不同的公共网络和不同的媒体传送或分发您的内容；对您的内容作出必要的变更以使内容符合、适应连接网络、装置、服务或介质的技术要求。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">10</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;您向</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">确认并保证您拥有所有必要的权利、权力和授权授予上述许可。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">十</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">一</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、注销账户</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1.</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1&nbsp;如您的账户存在以下情形，我们有权注销您的账户：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）您的行为违反法律规定或本协议的约定；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）基于法律规定或国家有权的机关的要求；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;如您向我们提出账户注销申请，当您的账户符合以下条件，经我们审核通过即可注销账户：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）账户处于正常状态，不涉及任何争议纠纷（包括投诉举报或被投诉举报），且未被有权机关采取限制措施；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）账户内无未完成的交易；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（C）账户内不存在其他未了结的权利义务或因注销账户而产生纠纷的情况；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（D）其他可能影响账户注销的情况。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;如您需要注销账户的，应按照我们要求的程序提出注销申请，经过我们审核通过后，予以注销。自我们通过用户预留的邮箱、短信等方式通知您之日起，账户注销生效。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;在账户注销过程中，如果您的账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，我们有权中止或终止您账户的注销程序而无需另行得到您的同意。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.5&nbsp;您已充分理解并同意，账户注销后，您将无法再继续使用我们提供的服务，不再拥有账户相关的权益。法定期限届满后，账户相关的内容或数据将被删除或匿名化，且无法恢复。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.6&nbsp;因注销账户所带来的不便或不利后果，由您自行承担。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">十</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">二</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、不包括其他保证</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">2</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;我们及我们的子公司关联公司及其许可人，不就以下各项向您作出陈述或保证：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）您对服务的使用将符合您的需求；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）您对服务的使用将无中断、及时、安全或没有错误；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（C）由于您使用服务而获得的任何信息将是准确的或可靠的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（D）作为服务的一部分向您提供的任何软件的运行或功能中的缺陷将被纠正。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">2</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;通过使用服务而下载或以其他方式获得的任何材料由您自行作出并承担风险，您将自行对由于下载任何该等材料而导致对电脑系统或其他装置的损害或数据的丢失负责。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">2</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;您从</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">获得的或通过服务或从服务获得的任何建议或信息（无论口头还是书面的）均不构成本协议中未明确规定的任何保证。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">2</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;我们进一步明确否认任何种类的保证和条件（无论明示还是默示的），包括但不限于适销性、适合特定目的及不侵权的默示保证和条件。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">十</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">三</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、责任限制</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">3</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;在遵守上文第</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">13</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">条全部规定的前提下，您理解并同意，我们及其关联公司和许可人不就以下事项对您承担责任：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）您无论由于何种原因和在任何责任理论项下发生的任何直接、间接、附带、特殊、后果性或惩罚性的损害。这应包括但不限于任何利润损失（无论是直接还是间接发生）、任何商誉或业务声誉损失、任何数据丢失、替代物品或服务的购买费用或其他无形损失；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）您可能产生的任何损失或损害，包括但不限于由下列原因导致的损失或损害：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px;text-indent: 32px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">(I)您对任何广告的完整性、准确性或其存在的信任， 或作为您与其广告出现在服务中的任何广告商或赞助人之间的任何关系或交易的结果；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px;text-indent: 32px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">(II)</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">对服务可能做出的变更，或永久或暂时停止提供服务（或服务中的任何功能）；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px;text-indent: 32px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">(III)对通过您使用服务而维持或传输的任何内容及其他通信数据的删除、毁坏或未能将其储存；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px;text-indent: 32px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">(IV)您未向</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">提供准确的帐户信息；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px;text-indent: 32px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">(V)您未对您的密码或帐户资料保持安全及保密；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px;text-indent: 32px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">(VI)电信设备出现故障不能进行数据传输；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px;text-indent: 32px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">(VII)因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成系统障碍不能执行业务的；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px;text-indent: 32px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">(VIII)由于黑客攻击、电信部门技术调整或故障等原因而造成的服务中断或者延迟。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">3</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;无论</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">是否接到通知或是否应已知晓引起任何该等损失的可能性，上文第15.1条中</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">对您的责任限制均应适用。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">十</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">四</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、商业活动</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">14</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;您理解并同意，我们可以在我们提供的产品或服务中进行下述商业活动：通过</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">APP、网站、H5页面或手机短信、邮件或其他合法方式向您发送关于我们或第三方的商业性广告或其他相关商业信息。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">14</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;我们特别提醒您，如果您收到的推送信息是关于第三方而非我们的，对于此类信息，您应当自行判断其内容的真实性并为自己的判断行为负责。除法律明确规定外，您因此类信息提供的内容而遭受的任何损失或损害，我们均不承担任何责任。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">十</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">五</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、广告</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;我们的部分服务由广告收入支持，可展示广告和推销。这些广告可能是针对存储于服务中的信息、通过服务提出的询问或其他信息的内容提供的。您同意我们可以在我们的服务上加载该等广告。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">5</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">在服务上的广告的方式、模式和范围可不经向您特别通知而变更。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px;text-indent: 32px">
    <span style="font-size: 14px;font-family: Calibri;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">十</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">六</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、侵权举报和处理</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">对关于符合适用国际知识产权法（包括中国的《著作权法》）的指称的著作权侵权通知做出回应以及终止重复侵权者帐户是</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的政策。</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">我们高度重视自由表达和个人、企业正当权利的平衡。依照法律规定删除违法信息是我们的法定义务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;侵犯企业或个人合法权益的侵权举报，包括但不限于涉及个人隐私、造谣与诽谤、商业侵权。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）涉及个人隐私：发布内容中直接涉及身份信息，如个人姓名、家庭住址、身份证号码、工作单位、私人电话等详细个人隐私；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）造谣、诽谤：发布内容中指名道姓（包括自然人和企业）的直接谩骂、侮辱、虚构中伤、恶意诽谤等；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（C）商业侵权：泄露企业商业机密及其他根据保密协议不能公开讨论的内容。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">用户在</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">发表的内容仅表明其个人的立场和观点，并不代表</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的立场或观点。如果个人或企业发现</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">上存在侵犯自身合法权益的内容，可以先尝试与作者取得联系，通过沟通协商解决问题。如您无法联系到作者，或无法通过与作者沟通解决问题，您可通过向</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">指定邮箱</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">contact@zetta-yotta.com</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">发送侵权投诉内容进行投诉。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;为了保证问题能够及时有效地处理，请务必提交真实有效、完整清晰的材料，否则投诉将无法受理。您需要向</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">提供的投诉材料包括：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）权利人对涉嫌侵权内容拥有商标权、著作权和/或其他依法可以行使权利的权属证明，权属证明通常是营业执照或组织机构代码证；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）举报人的身份证明，身份证明可以是身份证或护照；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（C）如果举报人非权利人，请举报人提供代表权利人进行举报的书面授权证明。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;为确保投诉材料的真实性，在侵权举报中，您还需要出具以下法律声明：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）我本人为所举报内容的合法权利人；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）我举报的发布在</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">社区中的内容侵犯了本人相应的合法权益；</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（C）如果本侵权举报内容不完全属实，本人将承担由此产生的一切法律责任，并承担和赔偿</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">因根据投诉人的通知书对相关帐号的处理而造成的任何损失，包括但不限于</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">因向被投诉方赔偿而产生的损失及</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">名誉、商誉损害等。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">6</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.5&nbsp;处理流程</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（A）出于网络平台的监督属性，并非所有申请都必须受理。</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">自收到举报的七个工作日内处理。处理期间，不提供任何电话、邮件及其他方式的查询服务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（B）出现</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">已经删除或处理的内容，但是百度、谷歌等搜索引擎依然可以搜索到的现象，是因为百度、谷歌等搜索引擎自带缓存，此类问题</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">无权也无法处理，因此相关申请不予受理。您可以自行联系搜索引擎服务商进行处理。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（C）此为</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">唯一的官方侵权投诉渠道，暂不提供其他方式处理此业务。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">（D）用户在</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">中的商业行为引发的法律纠纷，由交易双方自行处理，与</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">无关。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: Calibri;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">十</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">七</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、其他内容</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">17.1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;服务可包含对其他网站或内容或资源的超级链接。</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">可能并不控制由</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">以外的公司或个人提供的任何网站或资源。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">7</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;您认知并同意，</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">不对该等外部网站或资源的可用性负责，亦不对该等网络或资源上的或从该等网站或资源获得的任何广告、产品或其他材料加以认可。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">7</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;您认知并同意，</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">不对由于您由于那些外部的网站或资源的可用性或您对该等网站或资源上的或从该等网站或资源获得的任何广告、产品或其他材料的完整性、准确性或存在的信赖而发生的任何损失或损害承担责任。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px;text-indent: 32px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">十</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">八</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、本协议的变更</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">8</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">可不时对通用条款或附加条款作出变更。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">8</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;您理解并同意，如果您在通用条款或附加条款变更日期之后使用服务，则</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">将把您的使用视为接受更新后的通用条款或附加条款。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">十九</span><span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">、一般法律条款</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">19</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.1&nbsp;有时候您使用服务，您即可（作为您使用服务的结果或通过您对服务的使用）使用其他人或公司提供的某项服务或下载一个其他人或公司提供的软件，或购买其他人或公司提供的商品。您对这些其他服务、软件或商品的使用受限于您和相关公司或个人的单独条款。在此情况下，本协议不影响您和这些其他公司或个人的法律关系。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">19</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.2&nbsp;本协议构成您和</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">之间关于您使用服务（但不包括根据单独书面协议</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">向您提供的任何服务）的全部法律协议，并完全取代您和</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">先前就服务达成的任何协议。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">19</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.3&nbsp;</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">有义务对您提交的个人信息保密，未经允许不得透露给第三方。您同意</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">可通过电子邮件发送各种通知及各种非商业或商业性质的内容。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">19</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.4&nbsp;您同意，如果</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">未行使或未强制执行包含在本协议中的（或</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">在任何适用法律下有权享受的）任何法定权利或救济，不可视为对</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">权利的正式放弃，这些权利或救济仍对</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">有效。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">19</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.5&nbsp;如果对该等事项有司法决定权的任何法院，判定本协议的任何规定无效，则该等规定将从本协议中删除，而不影响本协议的其他部分。本协议的其余部分将继续有效并可强制执行。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">19</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.6&nbsp;您认知并同意，</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">所在公司集团（包含母公司、子公司、关联公司等）的每一成员应为本协议的第三方受益人，该等其他公司应有权直接强制执行和依赖赋予其利益（或权利）的本协议的任何规定。此外，没有任何人或公司应是本协议的第三方受益人。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">19</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">.7&nbsp;本协议及本协议项下您与</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">的关系，受中国法律管辖，但排除其冲突法规定。您与</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">均同意接受位于中国境内的法院的专属管辖权，以解决任何由本协议引起的法律事项。尽管有上述规定，您同意</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">仍被允许请求任何辖区内的禁制令救济（或同等类型的紧急法定救济）。</span>
</p>
<p class="paragraph text-align-type-justify" style=";text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: Calibri;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&nbsp;</span>
</p>
<p class="paragraph text-align-type-center" style="margin: 0 0 8px;text-align: center;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;font-weight: bold;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">免责声明</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">提供的所有的文章、课件、图片、视频及其他资源，若出处为&quot;</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&quot;，即为</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">公司原创或整理，版权归作者与</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">共同所有。若作者有版权声明的或文章从其他网站转载而附带有原所有站的版权声明者，其版权归属以附带声明为准。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">文章仅代表作者本人的观点，与公司立场无关，作者文责自负。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">所刊载的文章、图片及其他资料仅供参考使用，不作为诊断及医疗依据。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">所刊载的内容，并不代表同意其说法或描述，仅为提供更多信息，也不构成任何学术建议。</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">不完全保证网站内容的真实性，准确性，完整性，也不保证未来内容不会发生变更。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">鉴于用户可以使用长颈鹿智慧医学平台应用进行临床治疗方案的采集、上传和分享，我们现作出以下声明：</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">1.&nbsp;用户使用长颈鹿智慧医学平台应用进行脱敏后的临床治疗方案采集，长颈鹿智慧医学平台应用只是一个工具方，长颈鹿智慧医学平台公司不对用户临床治疗方案采集的真实性、有效性等负责。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">2.&nbsp;用户上传至长颈鹿智慧医学平台平台的临床治疗方案，目的为科研学术交流、临床经验分享和以患者利益为中心的讨论。临床治疗方案上传后只保存在用户个人账户中，在用户个人不分享和公开发布的情况下，并不会被他人获取和浏览。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">3.&nbsp;用户确保上传的临床治疗方案不侵犯任何第三方的权利，如出现因知识产权等方面引起的纠纷，由用户自行负责。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">4.&nbsp;凡在长颈鹿智慧医学平台应用上由用户以临床治疗方案讨论为目的的、自行公开发布的临床治疗方案采集，北京智煜健康科技有限公司享有永久使用权，可以在其名下长颈鹿智慧医学平台微信公众号、长颈鹿智慧医学平台 App、长颈鹿智慧医学平台网站、长颈鹿智慧医学平台H5页面及长颈鹿智慧医学平台微博等使用，且不需要提前通知该用户。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">除特别注明禁止转载的文章外，欢迎公益性网站转载本站资源，但转载时请务必注明出处为&quot;</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">&quot;，如有可能，请做链接。对于商业性实体，如无本站明确许可，不得非法使用本站资源。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">有权将在</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">内发表的文章用于网站的非商业用途，包括网站、电子期刊等，文章有附带版权声明者除外。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">部分资源来自互联网，我们转载的目的是用于学术交流与讨论，如果您认为我们的转载侵犯了您的权益，请与我们联系，我们将尽快采取行动保护您的正当权益。</span>
</p>
<p class="paragraph text-align-type-justify" style="margin: 0 0 8px;text-align: justify;font-family: 等线;font-size: 16px;line-height: 16px">
    <span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">之声明以及其修改权、更新权及最终解释权在法律允许的范围内均属</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">长颈鹿智慧医学平台</span><span style="font-size: 14px;font-family: 黑体;background: rgb(255, 255, 255);letter-spacing: 0;vertical-align: baseline">所有。</span>
</p>
<p class="paragraph text-align-type-left" style="margin: 4px 0;font-family: 等线;font-size: 16px;line-height: 16px">
    <br/>
</p>
<p>
    <br/>
</p>`,
	}),
};
</script>
